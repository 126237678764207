<template>
  <HyperCodeHeroArticleSection
    :id="item.fields.id"
    :title="headline"
    :media-on-top="isMediaOnTop"
    :second-title="secondTitle"
    :description-list="descriptionListProps"
    :text="description"
    :buttons="buttons"
    :media="media"
    :color-mode="lightDarkColorMode"
    v-bind="
      ContentfulLivePreview.getProps({
        entryId: item.fields.id,
        fieldId: 'headline'
      })
    "
  >
    <template #rteText>
      <LibRichTextRenderer v-if="descriptionRte" :rich-text="descriptionRte" />
    </template>
  </HyperCodeHeroArticleSection>
</template>

<script setup lang="ts">
import { HeroArticleSection as HyperCodeHeroArticleSection } from '@hypercodestudio/basler-components';
import type {
  HeadlineProps,
  DescriptionListProps,
  ColorMode
} from '@hypercodestudio/basler-components/dist/components/content-modules/sections/HeroArticleSection.vue';
import { getLinkIconPosition } from '~/utils/getLinkIconPosition';
import type { IHeroArticleSection } from '~/lib/ContentfulService';
import { isDefined } from '~/utils/guards/isDefined';
import { useMedia } from '~/composables/useMedia';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';
import type { ColorMode as ThemeColorMode } from '@hypercodestudio/basler-components/dist/models/ColorMode';
import LibRichTextRenderer from '~/components/lib/RichTextRenderer.vue';
import { mapHeroHeadline } from '~/utils/mapHeroHeadline';
import { mapHeroSubtitle } from '~/utils/mapHeroSubtitle';
import { ContentfulLivePreview } from '@contentful/live-preview';

interface Props {
  item: IHeroArticleSection;
  colorMode: ThemeColorMode;
}

const props = defineProps<Props>();
const { $textDictionary } = useNuxtApp();

const headline = computed<HeadlineProps>(() => mapHeroHeadline(props.item));

const isMediaOnTop = computed(() => props.item.fields.mediaPosition === 'top');

const secondTitle = computed(() => mapHeroSubtitle(props.item));

const descriptionListProps = computed<DescriptionListProps[] | undefined>(
  () => {
    const entries = [
      {
        details: props.item.fields.customer,
        term:
          props.item.fields.customerType === 'Partner'
            ? $textDictionary.value['heroArticleSection.partner.label']
            : $textDictionary.value['heroArticleSection.customer.label']
      },
      {
        details: props.item.fields.location,
        term: $textDictionary.value['heroArticleSection.location.label']
      },
      {
        details: props.item.fields.date,
        term: $textDictionary.value['heroArticleSection.date.label']
      }
    ].filter(
      (value): value is DescriptionListProps =>
        isDefined(value.details) && isDefined(value.term)
    );
    return entries.length > 0 ? entries : undefined;
  }
);

const lightDarkColorMode = computed<ColorMode>(() => {
  if (props.colorMode === 'dark-purple') {
    return 'dark';
  }

  return props.colorMode;
});

// Der Link Type beinhaltet aktuell nur einen Text. Hypercode wurde in Ticket #1260 darüber informiert.
const buttons = computed(() =>
  props.item.fields.links
    ?.filter((entry) => !isEntryResolveError(entry))
    ?.map((field) => ({
      text: field.fields.linkText,
      link: useBuildLinkInterface(field),
      size: field.fields.linkStyleSize,
      styleType: field.fields.linkStyle ?? 'default',
      iconName: field.fields.linkIcon,
      targetBlank: field.fields.linkTarget === '_blank',
      iconType: getLinkIconPosition(field.fields.linkIconPosition)
    }))
);

const description = computed(() => ({
  lead: false,
  content: props.item.fields.description ?? ''
}));

const descriptionRte = computed(() => props.item.fields.descriptionRte ?? '');

const media =
  props.item.fields.media &&
  (await useMedia(props.item.fields.media, { keepAspectRatio: true }));
</script>
